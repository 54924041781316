import * as React from "react";
import { Link } from "gatsby";
import Layout from "../layout/Layout";
import { WaveTop, WaveBottom } from "../components/Waves";

// markup
const NotFoundPage = () => {

    const today = new Date();
    const date = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`;
    const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

    return (
        <Layout title="Página no encontrada">
            <WaveTop />

            <main className="bg-white text-black">
                <div className="container max-w-5xl mx-auto p-8">

                    <h3 className="text-2xl">Página no encontrada 😅</h3>
                    <p className="whitespace-pre-line py-3">
                        Lo siento pero no hemos podido encontrar lo que estás buscando.
                    </p>

                    <Link to="/" title="Ir a la página inicial">Ir al inicio</Link>

                    <p className="mt-16 font-thin text-xs text-white">Fecha de compilación: {new Date().toLocaleString("es-ES", { timeZone: "Europe/Madrid" })}</p>
                </div>
            </main>

            <WaveBottom />
        </Layout>
    );
};

export default NotFoundPage;
